const vapidPublicKey = new Uint8Array([
        4, 18, 126, 252, 254, 214, 79, 199, 224, 33, 181,
        168, 16, 221, 253, 89, 196, 33, 94, 145, 242, 208,
        144, 217, 131, 133, 11, 223, 112, 125, 89, 187, 158,
        13, 165, 110, 198, 144, 166, 235, 31, 161, 32, 116,
        152, 236, 143, 250, 177, 215, 60, 19, 60, 135, 201,
        133, 182, 205, 139, 254, 98, 197, 229, 243, 144
    ]
);
const apiUrl = 'http://localhost:3000';

window.addEventListener('load', function () {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/sw.js')
            .then(function (reg) {
                // Registration was successful
                console.log('ServiceWorker registration successful');
                reg.pushManager.getSubscription().then(function (sub) {
                    if (sub == null) {
                        // Update UI to ask user to register for Push
                        console.log('Not subscribed to push service!');
                    } else {
                        // We have a subscription, update the database
                        console.log('Subscription JSON', JSON.stringify(sub));
                    }
                });
            })
            .catch(function (err) {
                // registration failed :(
                console.log('ServiceWorker registration failed: ', err);
            });

        // Subscribe to push
        navigator.serviceWorker.ready.then(function (reg) {
            reg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: vapidPublicKey
            }).then(function (sub) {
                // console.log('Subscription ready', sub.endpoint);
                return postSubscription(sub).then(function() {
                    console.log('Posted subscription');
                });
            }).catch(function (err) {
                if (Notification.permission === 'denied') {
                    console.warn('Permission for notifications was denied');
                } else {
                    console.error('Unable to subscribe to push', err);
                }
            });
        });
    }
    if ('Notification' in window) {
        const button = document.getElementById("subscribe-notifications");
        if (button) button.addEventListener('click', function (e) {
            e.preventDefault();
            Notification.requestPermission().then(function (result) {
                if (result === 'granted') {
                    //subscribePush();
                    // showNotification();
                }
            });
        });
    }
});

function postSubscription(subscription) {
    return fetch(apiUrl + '/push/subscription', {
        method: 'post',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            subscription
        }),
    });
}

function showNotification() {
    navigator.serviceWorker.getRegistration().then(function (reg) {
        const notifTitle = 'BachMi Updates';
        const options = {
            body: 'News content',
            data: {
                dateOfArrival: Date.now(),
                primaryKey: 1
            },
            actions: [
                {
                    action: 'read', title: 'Read More',
                },
                {
                    action: 'close', title: 'Close',
                },
            ]
        }
        reg.showNotification(notifTitle, options);
    });
}

